<template>
  <div class="select-box" :class="{invisible:!visible}"  @click.stop="itemClick">
    <div class="select-items">
      <div class="select-item" v-for="(item, index) in selections" :key="index" :class="(index == selectedIndex)?'selected':''" :data-tgt="item.tgt" :data-show-dot="item.showDot">{{ item.label }}</div>
    </div>


  </div>
</template>

<script>
export default {
  name: "SelectBox",
  props: {
    selections: {
      type: Array,
      default: function(){
        return [];
      }
    },

    selection: {
      default: 0
    },

    showHighlights: {
      default: true
    }
  },

  data: function(){
    return {
      visible: false,
      globalClickEnabled: false
    }
  },

  methods: {
    detectGlobalClick: function(){

      if(this.globalClickEnabled){
        console.log("global click");

        this.visible = false;
        this.removeGlobalClick();

      }
    },

    removeGlobalClick: function(){
      window.removeEventListener("click", this.detectGlobalClick);
      this.globalClickEnabled = false;
    },

    itemClick: function(e){
      if(!this.visible){
        this.visible = true;
        window.addEventListener("click", this.detectGlobalClick);
        this.globalClickEnabled = true;
      }else{

        this.visible = false;
        if(e.target.dataset.tgt){
          this.$emit("select", e);
        }

      }

    }
  },

  computed: {
    selectedIndex: function(){
      if(this.showHighlights){


        if(this.selections.length){
          if(isNaN(this.selection)){
            if(this.selection){
                return this.selections.findIndex((item) => {
                  return (item.label == this.selection)
                })
            }
          }

        }
        return this.selection;
      }

      return -1;
    }


  }


}
</script>

<style scoped>


  .select-box.invisible .select-items {
    display: none;


  }

  .select-box.invisible .select-item.selected {
    opacity: 0;
    display: block;
  }

  .select-box {
    position: absolute;
    user-select: none;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    cursor: pointer;

  }

  .select-item {
    padding: 0.25em 0.5em;
    color: #586283;
    cursor: pointer;
    background-color: #F1F0F6;


  }





  .bg-white .select-item {
    background-color: white;
    color: black;
    position: relative;
  }



  .thinkhr .select-items {
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    padding: 0.25em 1em;
    font-size: 0.9em;
    border: none;

  }

  .thinkhr .select-item {
    padding: 0.5em;
    background-color:white;
    color: #002264;
    position: relative;

  }



  .select-items {
    border: 1px solid black;
    position: absolute;
    top: 100%;
    width: 100%;
    box-sizing: border-box;


  }



  .select-item.selected {
    color: white;
    background-color: #39A0F9;
  }
</style>