<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <!-- DASHBOARD -->


        <div class="screen z-max" data-screen="post-job" :data-active="currentScreen=='post-job'">
          <img class="w-full max-w-100" src="/img/screens/indeed-post-job.jpg" />
          <div class="links">

          </div>
        </div>



      </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
//import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";

// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "indeed-screens",
  components: {DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      this.$store.commit("guidedMode", this.$route.query.guided);
      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }


    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'post-job',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,

    }
  },
  methods: {

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{


        this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      let tgtTab = e.target.dataset.tgtTab?e.target.dataset.tgtTab:"_blank";
      window.open(tgtUrl, tgtTab);
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "onlinehr"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          this.$refs['screens'].scrollTo(0,0);
          //window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "onlinehr"){

          this.$router.push({name: "onlinehr", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}




.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
html {
  background: #f3f2f1;
}
</style>
