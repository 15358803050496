<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <div class="screen z-max" data-screen="handbook-builder" :data-active="currentScreen=='handbook-builder'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder.png?v=10122022" />
          <div class="links">
            <screen-link left="21.5%" top="27%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-library" :data-active="currentScreen=='handbook-builder-library'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="88.25%" top="8%" width="11%" height="5%" dot-class="dot-right-center" >
              <select-box :selections="createOptions" selection="" @select="onLinkClick" :show-highlights="false" class="thinkhr"></select-box>
            </screen-link>

            <screen-link left="20.5%" top="50.5%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-library-actions" @click.native="onLinkClick"></screen-link>
            <screen-link left="1%" top="33%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson" @click.native="onLinkClick"></screen-link>
            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-library-actions" :data-active="currentScreen=='handbook-builder-library-actions'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-actions.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="88.25%" top="8%" width="11%" height="5%" dot-class="dot-right-center" >
              <select-box :selections="createOptions" selection="" @select="onLinkClick" :show-highlights="false" class="thinkhr"></select-box>
            </screen-link>

            <screen-link left="20.5%" top="50.5%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="29.5%" top="42.5%" width="11%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-library-settings" @click.native="onLinkClick"></screen-link>
            <screen-link left="1%" top="33%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson" @click.native="onLinkClick"></screen-link>
            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-library-settings" :data-active="currentScreen=='handbook-builder-library-settings'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-settings.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="127%" width="35%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-library-settings-ny" @click.native="onLinkClick"></screen-link>

            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-library-settings-ny" :data-active="currentScreen=='handbook-builder-library-settings-ny'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-settings.png?v=10122022" />
          <img class="w-full max-w-100 state-box" src="/img/screens/admin-handbook-builder-library-settings-ny.png?v=10122022" />

          <div class="links">

            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="37%" top="127%" width="14%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library-settings-ny-0" @click.native="onLinkClick"></screen-link>

            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>
        <div class="screen z-max" data-screen="handbook-builder-library-settings-ny-0" :data-active="currentScreen=='handbook-builder-library-settings-ny-0'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-settings.png?v=10122022" />
          <img class="w-full max-w-100 state-box" src="/img/screens/admin-handbook-builder-library-settings-ny-0.png?v=10122022" />

          <div class="links">

            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="32%" top="145%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="handbook-builder-library-settings-ny-16" @click.native="onLinkClick"></screen-link>

            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-library-settings-ny-16" :data-active="currentScreen=='handbook-builder-library-settings-ny-16'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-settings.png?v=10122022" />
          <img class="w-full max-w-100 state-box" src="/img/screens/admin-handbook-builder-library-settings-ny-16.png?v=10122022" />

          <div class="links">

            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="90%" top="0.5%" width="5%" height="6%" dot-class="dot-bottom-center" @click.native="showOverlay('notifications')"></screen-link>
          </div>
        </div>


        <div class="screen z-max" data-screen="handbook-builder-library-create-menu" :data-active="currentScreen=='handbook-builder-library-create-menu'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-library-create-menu.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson" :data-active="currentScreen=='handbook-builder-polarson'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="41%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

            <screen-link left="85.25%" top="8%" width="14%" height="5%" dot-class="dot-right-center" >
              <select-box :selections="actionsOptions" selection="" @select="onLinkClick" :show-highlights="false" class="thinkhr"></select-box>
            </screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-with-casual-friday" :data-active="currentScreen=='handbook-builder-polarson-with-casual-friday'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-with-casual-friday.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="41%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

            <screen-link left="85.25%" top="8%" width="14%" height="5%" dot-class="dot-right-center" >
              <select-box :selections="actionsOptions" selection="" @select="onLinkClick" :show-highlights="false" class="thinkhr"></select-box>
            </screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-hiring" :data-active="currentScreen=='handbook-builder-polarson-hiring'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-hiring.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-library" @click.native="onLinkClick"></screen-link>

            <screen-link left="1%" top="41%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson" @click.native="onLinkClick"></screen-link>
            <screen-link left="1%" top="56.5%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson-hiring-job-descriptions" @click.native="onLinkClick"></screen-link>


            <screen-link left="85.25%" top="8%" width="14%" height="5%" dot-class="dot-right-center" >
              <select-box :selections="actionsOptions" selection="" @select="onLinkClick" :show-highlights="false" class="thinkhr"></select-box>
            </screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-hiring-job-descriptions" :data-active="currentScreen=='handbook-builder-polarson-hiring-job-descriptions'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-hiring-job-descriptions.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

            <screen-link left="83%" top="32.5%" width="16%" height="4%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring-job-descriptions-preview" @click.native="onLinkClick"></screen-link>

            <screen-link left="83%" top="24.6%" width="16%" height="4%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-hiring-job-descriptions-preview" :data-active="currentScreen=='handbook-builder-polarson-hiring-job-descriptions-preview'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-hiring-job-descriptions-preview.png?v=10122022" />
          <div class="links">


            <screen-link left="71%" top="84%" width="11%" height="4.5%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring-job-descriptions" @click.native="onLinkClick"></screen-link>

          </div>
        </div>


        <div class="screen z-max" data-screen="handbook-builder-polarson-actions-custom-policy" :data-active="currentScreen=='handbook-builder-polarson-actions-custom-policy'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-actions-custom-policy.png?v=10122022" />
          <div class="links">

            <screen-link left="60.5%" top="179%" width="10%" height="4%" dot-class="dot-top-center" data-tgt="handbook-builder-polarson-actions-custom-policy-new" @click.native="onLinkClick"></screen-link>
            <screen-link left="50.25%" top="179%" width="10%" height="4%" dot-class="dot-top-center" data-tgt="handbook-builder-polarson" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-actions-custom-policy-new" :data-active="currentScreen=='handbook-builder-polarson-actions-custom-policy-new'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-actions-custom-policy-new.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="23.75%" width="41.5%" height="4.5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson-actions-custom-policy-new-filled" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="handbook-builder-polarson-actions-custom-policy-new-filled" :data-active="currentScreen=='handbook-builder-polarson-actions-custom-policy-new-filled'">
          <img class="w-full max-w-100" src="/img/screens/admin-handbook-builder-polarson-actions-custom-policy-new-filled.png?v=10122022" />
          <div class="links">
            <screen-link left="12.5%" top="1%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="handbook-builder" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="8%" width="2.5%" height="5%" dot-class="dot-right-center" data-tgt="handbook-builder-polarson-hiring" @click.native="onLinkClick"></screen-link>

            <screen-link left="82.5%" top="17%" width="16%" height="4.5%" dot-class="dot-left-center" data-tgt="handbook-builder-polarson-with-casual-friday" @click.native="onLinkClick"></screen-link>

          </div>
        </div>






      </div>
      <div class="notifications" @click="overlayPanel = false" v-if="overlayPanel === 'notifications'">
        <img src="/img/screens/admin-handbook-builder-library-settings-notifications.png?v=10122022" class="w-full" />
      </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";
import SelectBox from "@/components/SelectBox";
// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "handbook-builder-screens",
  components: {SelectBox, ScreenLink, DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      this.$store.commit("guidedMode", this.$route.query.guided);
      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }


  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'handbook-builder',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,
      notifications: false,
      createOptions: [

        { label: "New Handbook", tgt: "handbook-builder-library-create-menu", showDot: true},
        { label: "New Custom Policy", tgt: ""},
      ],
      actionsOptions: [

        { label: "Link Custom Policy", tgt: "handbook-builder-polarson-actions-custom-policy", showDot: true},
        { label: "Handbook Settings", tgt: ""},
        { label: "Download Word (.docx)", tgt: ""},
        { label: "Download PDF (.pdf)", tgt: ""},
        { label: "Download Individual State", tgt: ""},
        { label: "View Activity Stream", tgt: ""},
      ],

    }
  },
  methods: {

    close: function(){
      window.close();
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{


        this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "thinkhr"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          //this.$refs['screens'].scrollTo(0,0);
          window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "thinkhr"){

          this.$router.push({name: "thinkhr", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}

.screen-container {

  margin: 0px auto;

}

#app[data-feedback-mode="true"] .screen-container {

  margin: 0;

}


.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

.notifications {
  position: absolute;
  right: 7%;
  top: 4%;
  width: calc(350/1580 * 100%);
  z-index: 100;

}

.state-box {
  position: absolute;
  left: 0;
  bottom: 0;

}

</style>

<style>
html {
  background: #F3F4F9;
}
</style>
